import React from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import './styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-links-socials-container">
        <div className="footer-links">
          {/* Navigation link for how it works and other pages */}
          <Link to="/how-it-works">How It Works</Link>
          <Link to="/privacy-policy">Privacy Policy</Link> {/* Link to the Privacy Policy page */}
          {/* Additional links can be added here */}
        </div>
        <div className="footer-socials">
          {/* Social media icons with external links */}
          <a href="https://www.instagram.com/reciprofy" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://www.linkedin.com/company/reciprofy/" target="_blank" rel="noopener noreferrer">
            <LinkedInIcon />
          </a>
          <a href="https://www.youtube.com/channel/UC1w3P72DFqbMx1-7Y1AFJFg" target="_blank" rel="noopener noreferrer">
            <YouTubeIcon />
          </a>
          {/* Email contact link */}
          <a href="mailto:info@Reciprofy.com" target="_blank" rel="noopener noreferrer">
            <EmailIcon />
            <span>Contact Us: info@Reciprofy.com</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
