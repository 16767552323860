import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useUserContext } from '../contexts/UserContext';
import './styles/Navbar.css';

const Navbar = () => {
    const { currentUser, userType } = useUserContext();
    const navigate = useNavigate();
    const auth = getAuth();

    // Handling the sign out process
    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
            // Optionally, display an error message to the user
        }
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <NavLink to="/">Reciprofy</NavLink>
            </div>
            <div className="navbar-links">
                <NavLink to="/#how-it-works-section">How It Works</NavLink>
                <NavLink to="/#features-section">Features</NavLink>
                <NavLink to="/events">Events</NavLink> {/* New link for the Events page */}
                {currentUser && (
                    <>
                        {userType === 'athlete' && <NavLink to="/profilepage">Edit Athlete Profile</NavLink>}
                        {userType === 'brand' && <NavLink to="/brandprofile">Edit Brand Profile</NavLink>}
                        <NavLink to="/profiledashboard">Dashboard</NavLink>
                        <button onClick={handleSignOut} className="nav-button">Sign Out</button>
                    </>
                )}
                {!currentUser && (
                    <>
                        <NavLink to="/signin">Sign In</NavLink>
                        <NavLink to="/signup">Sign Up</NavLink>
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
