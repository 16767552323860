import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import './firebase';
import './App.css';

// Lazy loading components
const MainSection = lazy(() => import('./components/MainSection'));
const Features = lazy(() => import('./components/Features'));
const HowItWorks = lazy(() => import('./components/HowItWorks'));
const Signup = lazy(() => import('./components/Signup'));
const SignIn = lazy(() => import('./components/SignIn'));
const ProfilePage = lazy(() => import('./components/ProfilePage'));
const BrandProfilePage = lazy(() => import('./components/BrandProfilePage'));
const ProfileDashboard = lazy(() => import('./components/ProfileDashboard'));
const ResetPasswordComponent = lazy(() => import('./components/ResetPasswordComponent'));
const PasswordResetHandler = lazy(() => import('./components/PasswordResetHandler'));
const EventsPage = lazy(() => import('./components/EventsPage'));  // Lazy loaded EventsPage component
const EventDetailPage = lazy(()=> import ('./components/EventDetailPage')); 
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy')); // Lazy loaded PrivacyPolicy component



const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash]);

  return null;
};

const LoadingIndicator = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </div>
);

const App = () => {
  return (
    <Router>
      <UserProvider>
        <ScrollToTop />
        <div className="App">
          <Navbar />
          <div className="main-content">
            <Suspense fallback={<LoadingIndicator />}>
              <Routes>
                <Route path="/" element={<>
                  <MainSection />
                  <Features />
                  <HowItWorks />
                </>} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/resetpassword" element={<ResetPasswordComponent />} />
                <Route path="/passwordreset" element={<PasswordResetHandler />} />
                <Route path="/profilepage" element={<ProfilePage />} />
                <Route path="/brandprofile" element={<BrandProfilePage />} />
                <Route path="/profiledashboard" element={<ProfileDashboard />} />
                <Route path="/events" element={<EventsPage />} />  {/* Route for the Events Page */}
                <Route path="/event/:eventId" element={<EventDetailPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
              </Routes>
            </Suspense>
          </div>
          <Footer />
        </div>
      </UserProvider>
    </Router>
  );
};

export default App;
