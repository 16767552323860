import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const UserContext = createContext({
  currentUser: null,
  userType: null,
  userData: null,
  loading: true,
});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const firestore = getFirestore();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        // Attempt to fetch user data from both 'athletes' and 'brands' collections
        const athleteRef = doc(firestore, 'athletes', user.uid);
        const brandRef = doc(firestore, 'brands', user.uid);
        const athleteSnap = await getDoc(athleteRef);
        const brandSnap = await getDoc(brandRef);

        if (athleteSnap.exists()) {
          setUserType('athlete');
          setUserData(athleteSnap.data());
        } else if (brandSnap.exists()) {
          setUserType('brand');
          setUserData(brandSnap.data());
        } else {
          // Handle case where user is neither in 'athletes' nor 'brands'
          setUserType(null);
          setUserData(null);
        }
      } else {
        setUserType(null);
        setUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  const contextValue = {
    currentUser,
    userType,
    userData,
    loading
  };

  return (
    <UserContext.Provider value={contextValue}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
